import React from "react"
import styled from "styled-components"
import { Link } from 'gatsby'
import { Container, Breadcrumb } from 'react-bootstrap'
import { Section } from '../components/Section'
import Layout from "../components/layout"
import SEO from "../components/seo"
import PatternImg from "../images/line-pattern.png"

const SitemapGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-column-gap: 30px;
  @media (min-width: 576px){ 
    grid-template-columns: repeat(3, 1fr);     
  }
`
const SitemapItem = styled.div`
  & h2{
    font-size: 32px;
		line-height:42px;
		font-weight: bold;
    margin-bottom:10px;
    color:#1B1464;
    text-transform: uppercase;    
		@media(max-width: 767px){
			font-size: 28px;
			line-height:38px;
		}
    & a{
      color:#1B1464;
      &:hover {
        color:#CB0000;
      }
    }
  }
  & ul{
    line-height:28px;
    & li{
      & a{
        color: rgba(102, 102, 102, 0.7);
        &:hover {
          color:#020304;
        }
      }  
    }
  }
}
`
class SiteMap extends React.Component {  
  render() {
    return (
      <Layout>
        <SEO title="Site Map" />
        <section style={{ background: `url(${PatternImg})` }}>
          <Container className='position-relative'>
            <Breadcrumb className='beadcrumbs-outer'>
              <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
              <Breadcrumb.Item active>Site Map</Breadcrumb.Item>
            </Breadcrumb>
          </Container>
        </section>
        <Section className='section' pt='100px' pb='100px'>
          <Container>
          <SitemapGrid>
                <SitemapItem>
                    <h2><Link to='/metal-carports'>Carports</Link></h2>
                    <ul>
                        <li><Link to='/single-car-carports'><span>Single Car Carports</span></Link></li>
                        <li><Link to='/double-car-carports'><span>Double Car Carports</span></Link></li>		
                        <li><Link to='/triple-car-carports'><span>Triple Car Carports</span></Link></li>
                        <li><Link to='/rv-carports'><span>RV Carports</span></Link></li>		
                    </ul>
                    <h2><Link to='/metal-garages'>Garages</Link></h2>
                    <ul>
                        <li><Link to='/single-car-garages'><span>Single Car Garages</span></Link></li>
                        <li><Link to='/double-car-garages'><span>Double Car Garages</span></Link></li>			
                        <li><Link to='/triple-car-garages'><span>Triple Car Garages</span></Link></li>
                        <li><Link to='/rv-garages'><span>RV Garages</span></Link></li>			
                    </ul>
                    <h2><Link to='/metal-barns'>Barns</Link></h2>
                    <ul>
                        <li><Link to='/regular-roof-barns'><span>Regular Roof Barns</span></Link></li>
                        <li><Link to='/raised-center-barns'><span>Raised Center Barns</span></Link></li>
                        <li><Link to='/straight-roof-barns'><span>Straight Roof Barns</span></Link></li>
                    </ul>
                </SitemapItem> 
                <SitemapItem>
                     <h2><Link to='/metal-buildings'>Metal Buildings</Link></h2>
                    <ul>
                      <li><Link to='/clear-span-buildings'><span>Clear Span Buildings</span></Link></li>	
                      <li><Link to='/workshop-buildings'><span>Workshop Buildings</span></Link></li>			
                      <li><Link to='/storage-buildings'><span>Storage Buildings</span></Link></li>			
                      <li><Link to='/insulated-buildings'><span>Insulated Buildings</span></Link></li>			
                    </ul>
                    <h2>Payment Options</h2>
                    <ul>	
                        <li><Link to='/rent-to-own'><span>Rent To Own</span></Link></li>	
                        <li><Link to='/financing'><span>Financing</span></Link></li>	
                    </ul>                
                    <h2>Resources</h2>
                    <ul>				
                        <li><Link to='/roof-styles'><span>Roof Styles</span></Link></li>					
                        <li><Link to='/certified-buildings'><span>Certified Buildings</span></Link></li>
                        <li><Link to='/site-preparation'><span>Site Preparation</span></Link></li>	
                        <li><Link to='/faq'><span>FAQ's</span></Link></li>
                    </ul>                      
                </SitemapItem>    
                <SitemapItem>
                    <h2>Quick Links</h2>
                    <ul>						
                        <li><Link to='/about'><span>About Us</span></Link></li>								
                        <li><Link to='/contact-us'><span>Contact Us</span></Link></li>					
                    </ul>          
                    <h2>Other Links</h2>
                    <ul>						
                      <li><Link to='/privacy-policy'><span>Privacy Policy</span></Link></li>
                    </ul>     
                </SitemapItem>           
            </SitemapGrid>
          </Container>
        </Section>

      </Layout>
    )
  }
}

export default SiteMap
